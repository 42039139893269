/* button {
    box-shadow: 10px 10px 10px #a2a2a2;
    margin-left: 5%;
    font-size: 26px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-top: 30px;
    border: 2px solid black;

} */

.dropButton {
    box-shadow: 10px 10px 10px #a2a2a2;
    margin-left: 5%;
    font-size: 26px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-top: 30px;
    border: 2px solid black;
}

.menu {
    position: absolute;
    list-style-type: none;
    margin: 5px 0;
    margin-left: 5%;
    padding: 0px;
    width: 150px;
    font-family: 'Poppins', sans-serif;
}

.menu>li {
    margin: 0;
    background-color: white;
    width: 160px;
}

.menu>li:hover {
    background-color: rgb(221, 221, 221);
}

.menu>li>a {
    width: 100%;
    height: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 5px;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    cursor: pointer;
    font-size: 20px;
}