nav {
    top: 0;
    width: 100%;
    height: 50px;
}

.navbar-nav {
    margin-top: 0 !important;
}

.list {
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.items {
    margin-right: 20px;
    font-size: 20px;
    text-transform: uppercase;
    color: #f1f1f1;
    cursor: pointer;
}

.bground {
    background-image: linear-gradient(white, #ff9999, #ff4d4d);
    height: 100vh;
    /* background-size: cover; */
}

.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: #000;
    font-size: 18px;
}

#navA {
    text-decoration: none;
    color: maroon;
    cursor: pointer;
    display: inline;
    float: left;
    font-size: 18px;
    font-weight: bold;
}

#navA:hover {
    font-size: 18.5px;
    color: maroon;
}

li {
    margin-left: 15px;
}