@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.maindash {
    position: absolute;
}

h1 {
    padding-left: 5%;
    text-align: left;
    padding-top: 7.5%;
    font-size: 300%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: rgba(149, 16, 16, 1);

}

p {
    text-align: left;
    font-size: 200%;
    padding-left: 5%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}