.attendees-table {
  max-width: 600px;
  margin: 0 auto;
  table-layout: fixed;
  height: 500px;
  overflow-x: auto;
  overflow-y: auto;
  border-collapse: collapse;
}

.attendees-table th {
  font-weight: bold;
  background-color: #f2f2f2;
  white-space: nowrap;
  min-width: 100px;
  max-width: 300px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.attendees-table td {
  padding: 0.5rem;
  border: 1px solid #ddd;
  min-width: 100px;
  max-width: 300px;
  text-align: center;
  vertical-align: middle;
}

.attendees-table thead {
  text-align: center;
}

.attendees-table td:last-child,
.attendees-table th:last-child {
  padding-right: 0;
  width: auto;
  text-align: center;
}

.attendees-table a {
  color: #007bff;
}

.attendees-table-wrapper {
  max-width: 1500px;
  min-width: 700px;
  margin: 0 auto;
  padding: 20px;
}

.pagination-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
}

.attendees-table-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
}

.attendees-table-wrapper {
  flex-grow: 1;
  overflow-y: auto;
}

.attendees-table {
  height: 500px;
}

.pagination-wrapper {
  margin-top: 10px;
}