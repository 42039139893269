@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.App {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Button {
  color: white;
  background-color: rgb(142, 142, 142);
  padding: 10px;
  width: 100px;
  font-weight: 800;
  letter-spacing: 2;
  cursor: pointer;
  border-radius: 10px;
  transform: all 1s cubic-bezier(0.42, 0, 0.17, 0.71);
  margin: 10px;
}

.Button:hover {
  background-color: red;
}

.dialog {
  padding: 20px;
  background: white;
  position: absolute;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(37, 37, 37, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-content {
  background-color: white;
  margin: 0;
  width: 400px;
  height: 400px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
}

.close {
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

.close > button {
  height: 36px;
  width: 36px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
  padding-bottom: 5px;
}

@keyframes anime {
  0% {
    font-size: 10px;
  }
  30% {
    font-size: 15px;
  }
  100% {
    font-size: 12px;
  }
}

.detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0;
  height: 40px;
  border-bottom: 1px dashed;
  animation: slidein 2s;
}
.detail-first-child {
  border-top: 1px dashed;
  margin-top: 20px;
}

.detail-header {
  font-weight: bold;
  font-size: 1rem;
}

.detail-content {
  font-size: 1rem;
  padding-left: 5px;
}

.green {
  color: rgb(153, 255, 0);
}
.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
}
.red {
  color: red;
}

.message {
  font-size: 0.8rem;
}
.small {
  font-size: 0.9rem;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.errorMessage {
  display: flex;
  align-content: center;
  align-items: center;
} */
nav {
    top: 0;
    width: 100%;
    height: 50px;
}

.navbar-nav {
    margin-top: 0 !important;
}

.list {
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.items {
    margin-right: 20px;
    font-size: 20px;
    text-transform: uppercase;
    color: #f1f1f1;
    cursor: pointer;
}

.bground {
    background-image: linear-gradient(white, #ff9999, #ff4d4d);
    height: 100vh;
    /* background-size: cover; */
}

.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: #000;
    font-size: 18px;
}

#navA {
    text-decoration: none;
    color: maroon;
    cursor: pointer;
    display: inline;
    float: left;
    font-size: 18px;
    font-weight: bold;
}

#navA:hover {
    font-size: 18.5px;
    color: maroon;
}

li {
    margin-left: 15px;
}
/* Styling for top nav bar */


body {
    background: linear-gradient(rgb(227, 227, 227), rgb(168, 168, 168));
    background-repeat: no-repeat;
    background-attachment: fixed;
}

h5 {
    padding-top: 20px;
    text-shadow: 3px 3px 3px #450808;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding-right: 20px;
    /* font-size: 6vw; */
    /* font-size: 20px; */
}

#navC {
    color: white;
    text-decoration: none;

}

#navC:hover {
    color: rgb(0, 0, 0);
    font-weight: 600;
}

#navH5 {
    text-decoration: none;
    margin-left: .5%;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 5%;
    width: 100%;
    background-color: maroon;
    color: white;
    text-align: center;
}
 .maindash {
    position: absolute;
} 
p {
    text-align: left;
    font-size: 200%;
    padding-left: 5%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}


table {
    font-size: 90%;
    width: 85%;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    background-color: white;
    margin-left: 5%;
    margin-top: 5%;
    border: 2px solid #000000;
    box-shadow: 10px 10px 10px #a2a2a2;
  }
  
td, th {
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right:3%;
    font-size: 150%;
  }
  

tr:nth-child(even) {
    background-color: #dfdfdf;
  }


h2 {
  display: inline-block;
    font-size: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-left: 10%;
    margin-top: 10px;
  }

.maindash {
    position: absolute;
}

h1 {
    padding-left: 5%;
    text-align: left;
    padding-top: 7.5%;
    font-size: 300%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: rgba(149, 16, 16, 1);

}

p {
    text-align: left;
    font-size: 200%;
    padding-left: 5%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.events-table {
  max-width: 600px;
  margin: 0 auto;
  table-layout: fixed;
  overflow-x: auto;
  border-collapse: collapse;
}

.events-table th {
  font-weight: bold;
  background-color: #f2f2f2;
  white-space: nowrap;
  min-width: 100px;
  max-width: 300px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.events-table td {
  padding: 0.5rem;
  border: 1px solid #ddd;
  min-width: 100px;
  max-width: 300px;
  text-align: center;
  vertical-align: middle;
}

.events-table thead {
  text-align: center;
}

.events-table td:last-child,
.events-table th:last-child {
  padding-right: 0;
  width: auto;
  text-align: center;
}

.events-table a {
  color: #007bff;
}

.events-table-wrapper {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
}

.pagination-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
}
.attendees-table {
  max-width: 600px;
  margin: 0 auto;
  table-layout: fixed;
  height: 500px;
  overflow-x: auto;
  overflow-y: auto;
  border-collapse: collapse;
}

.attendees-table th {
  font-weight: bold;
  background-color: #f2f2f2;
  white-space: nowrap;
  min-width: 100px;
  max-width: 300px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.attendees-table td {
  padding: 0.5rem;
  border: 1px solid #ddd;
  min-width: 100px;
  max-width: 300px;
  text-align: center;
  vertical-align: middle;
}

.attendees-table thead {
  text-align: center;
}

.attendees-table td:last-child,
.attendees-table th:last-child {
  padding-right: 0;
  width: auto;
  text-align: center;
}

.attendees-table a {
  color: #007bff;
}

.attendees-table-wrapper {
  max-width: 1500px;
  min-width: 700px;
  margin: 0 auto;
  padding: 20px;
}

.pagination-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
}

.attendees-table-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
}

.attendees-table-wrapper {
  flex-grow: 1;
  overflow-y: auto;
}

.attendees-table {
  height: 500px;
}

.pagination-wrapper {
  margin-top: 10px;
}
.container {
    max-width: 500px;
    margin: 0 auto;
  }  
/* button {
    box-shadow: 10px 10px 10px #a2a2a2;
    margin-left: 5%;
    font-size: 26px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-top: 30px;
    border: 2px solid black;

} */

.dropButton {
    box-shadow: 10px 10px 10px #a2a2a2;
    margin-left: 5%;
    font-size: 26px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-top: 30px;
    border: 2px solid black;
}

.menu {
    position: absolute;
    list-style-type: none;
    margin: 5px 0;
    margin-left: 5%;
    padding: 0px;
    width: 150px;
    font-family: 'Poppins', sans-serif;
}

.menu>li {
    margin: 0;
    background-color: white;
    width: 160px;
}

.menu>li:hover {
    background-color: rgb(221, 221, 221);
}

.menu>li>a {
    width: 100%;
    height: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 5px;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    cursor: pointer;
    font-size: 20px;
}
