 .maindash {
    position: absolute;
} 
p {
    text-align: left;
    font-size: 200%;
    padding-left: 5%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}


table {
    font-size: 90%;
    width: 85%;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    background-color: white;
    margin-left: 5%;
    margin-top: 5%;
    border: 2px solid #000000;
    box-shadow: 10px 10px 10px #a2a2a2;
  }
  
td, th {
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right:3%;
    font-size: 150%;
  }
  

tr:nth-child(even) {
    background-color: #dfdfdf;
  }


h2 {
  display: inline-block;
    font-size: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-left: 10%;
    margin-top: 10px;
  }
